<template>
  <v-dialog v-model="visible" max-height="500" max-width="700">
    <v-card>
      <v-toolbar dense color="gray" dark>
        <v-toolbar-title class="title" justify="center">Neue Gesundheit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="form-content">
        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="5">
              <label for="name:">Abkürzung</label>
              <v-text-field class="textFieldOne" outlined v-model="health.name" :rules="rules.name"
                @input="abbreviationInput"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label for="name:">Beschreibung</label>
              <v-text-field class="textFieldTwo" outlined v-model="health.description"
                :rules="rules.description"></v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-btn type="submit" class="emptyForm" style="margin-left:95px; max-width:360px; text-color:black;"
              :loading="isLoading" color="primary" :disabled="disableErstellen || !valid">Erstellen
            </v-btn>
            <v-btn type="button" class="emptyForm" style="margin-left:25px;" :loading="isLoading" color="gray"
              :disabled="disableErstellen || !valid" @click="resetFormAndCloseModal">Abbrechen</v-btn>
          </v-row>
        </v-form>
        <v-alert type="success" elevation="20" v-show="showAlert" :color="alertColor" class="alert">
          {{ alertText }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import UpdateHealth from "./UpdateHealth";

export default {
  props: {
    health: {
      type: Object,
      default: () => ({ id: null, name: "", description: "" })
    },
    visible: Boolean,
    itemList: Array
  },
  data() {
    return {
      disableErstellen: true,
      isLoading: false,
      dialog: false,
      showAlert: false,
      alertText: "",
      alertColor: "",
      rules: {
        name: [
          v => !!v || "Name ist erforderlich",
          v => {
            if (!v) return true; // Allow empty input
            if (v.length <= 3) {
              return true;
            } else {
              return "Die Abkürzung darf höchstens 3 Zeichen umfassen"
            }
          }
        ],
        description: [v => !v || !!v || "Beschreibung ist erforderlich"]
      },
      valid: false
    };
  },
  computed: {
    formTitle() {
      return this.health && this.health.id
        ? "Massnahme bearbeiten"
        : "Neue Massnahme";
    }
  },
  components: {
    UpdateHealth
  },
  methods: {
    abbreviationInput() {
      const inputValue = this.health.name;

      if (inputValue.length <= 3) {
        this.disableErstellen = false;
      } else {
        this.disableErstellen = true;
      }
    },
    closeModal() {
      this.$emit("close");
    },
    showUpdateDialog() {
      this.dialog = true;
    },
    submitForm() {
      this.isLoading = true;
      this.disableErstellen = true;


      const requestBody = {
        name: this.health.name,
        description: this.health.description
      };

      if (!this.health.id) {
        axios
          .post(
            "https://tilia.rrota.org/api/Health/Create",
            requestBody
          )
          .then(response => {
            this.isLoading = false;
            if (Array.isArray(this.itemList)) {
              this.$set(this.itemList, this.itemList.length, response.data);
            } else {
              this.itemList = [response.data];
            }
            if (response.status === 200) {
              this.showAlert = true;
              this.alertColor = "success";
              this.alertText = "Das neue Gesundheit wurde erfolgreich erstellt";
              this.$emit("updated-table", true);
              setTimeout(() => {
                this.closeModal();
                this.showAlert = false;
                this.resetForm();
              }, 2000);
            }
          })
          .catch(error => {
            console.log(error);
            this.showAlert = true;
            this.isLoading = false;
            this.alertColor = "error";
            this.alertText = "Fehler beim Erstellen der Gesundheit";
            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
          });
      }
    },
    ///////////////////////////
    resetForm() {
      this.$refs.form.reset();
      this.valid = true;
    },
    ///////////////////////////
    resetFormAndCloseModal() {
      this.resetForm();
      this.closeModal();
    }
  }
};
</script>
<style>
.alert {
  position: fixed !important;
  width: 100% !important;
  max-width: 784px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px 100px !important;
}

.form-content {
  margin-top: 20px;
}
</style>
